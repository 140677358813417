/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import elderlyWomanPhone from '../images/heroes/woman-talking-on-mobile-phone.jpg'
import womanPosingPhone from '../images/people/posing-with-phone.jpg'
import manHoldingTablet from '../images/people/holding-tablet.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import CobraBanner from '../components/CobraBanner'

import {
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
          description:
            'Compare Aetna Medicare plans and let us help you enroll in the one that fits your healthcare needs. Call us today at 855-200-569',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '120760',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
        header: header(),
        footer: footer(data?.allBrandyDisclaimer?.edges),
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero reverse-content-desktop"
              image={
                <img
                  src={elderlyWomanPhone}
                  alt="elderly woman talks on mobile phone and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare Insurance Plans{' '}
                  </Typography>

                  <Typography variant="h4">
                    We speak Medicare, so you don’t have to{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <CountdownTimer />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-dollar.svg"
                alt="location icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">What is Medicare?</Typography>
              <Typography variant="body">
                Medicare is a federally funded health care program administered
                by The Centers for Medicare and Medicaid Services (CMS), a
                division of the U.S. Department of Health and Human Services
                (HHS).
              </Typography>
              <Typography variant="body">
                Depending on what you choose, Medicare plans can help pay for
                your medical expenses, such as doctor's visits, hospital
                services, prescription drugs and certain over-the-counter
                medications, long-term care, or even your out-of-pocket health
                care costs. Talk to a licensed agent today to find out if you're
                eligible for Medicare!
              </Typography>
              <Typography variant="h5">
                Medicare helps provide health insurance benefits for:
              </Typography>
              <List>
                <ListItem>
                  Working with the top insurance providers across the nation, we
                  offer a variety of Medicare Advantage and Medicare Part D
                  plans so you can select the plan that fits your personal needs
                </ListItem>
                <ListItem>
                  Our over 10 years as an insurance broker means you’re getting
                  expert experience
                </ListItem>
                <ListItem>
                  In addition to our online platform, over 70 US-based and
                  US-licensed agents are available for support and service
                </ListItem>
                <ListItem>
                  Become one of the more than 100,000 people we’ve helped
                  navigate the Medicare journey since just 2017
                </ListItem>
              </List>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanPosingPhone}
              alt="an elderly woman poses with cell phone in hand"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Aetna is Medicare health insurance made easy
              </Typography>
              <Typography variant="body">
                Whether you're new to Medicare or a seasoned veteran, finding
                the right plan can be confusing—maybe even a bit overwhelming.
              </Typography>
              <Typography variant="body">
                Fear not: trained agents help take the mystery out of Medicare!
                They're experts on all the rules and regulations surrounding
                Medicare plans in your area, and your agent will work with you
                one-on-one to make sure your plan fits your needs, your family's
                needs, and your budget.
              </Typography>
            </>
          }
        />

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Find the best Medicare plan for you with Aetna
              </Typography>
              <Typography variant="body">
                You're one-of-a-kind. You need a Medicare plan that fits you,
                your situation, and your needs. Fortunately, Medicare has a plan
                for virtually everyone!
              </Typography>
              <Typography variant="body">
                Original Medicare is provided through the federal government,
                while Medicare Advantage and Prescription Drug plans are offered
                through private insurance companies. Let a friendly licensed
                agent help you find the right plan today. Your agent will ask
                you questions about your health care needs and help you through
                every step of the enrollment process.
              </Typography>
              <Typography variant="h4">The Parts of Medicare:</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part A (hospital & hospice coverage)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/parta"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-stethoscope.svg"
                    alt="stethoscope icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part B (medical coverage & preventative care)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/medicare-part-b"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../images/icons/purple-health.svg"
                    alt="health icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Part C (Medicare Advantage)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={manHoldingTablet}
              alt="an elderly man talks on phone while holding tablet"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan Today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a plan? Get started by speaking with a
                licensed agent today at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  // Adding comment to trigger build

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
